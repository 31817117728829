import React, {useEffect} from 'react';
import { Container, Row, Col, Form, FormGroup } from 'reactstrap';
import Helmet from '../components/Helmet/Helmet';
import { Link, useParams } from 'react-router-dom';
import blogData from '../assets/data/blogData';
import commentImg01 from '../assets/all-images/ava-1.jpg';
import commentImg02 from '../assets/all-images/ava-2.jpg';
import commentImg03 from '../assets/all-images/ava-3.jpg';
import '../styles/blog-details.css';

const BlogDetails = () => {

  const {slug} = useParams()

  const blog = blogData.find(blog => blog.title === slug)

  // useEffect(()=>{
  //   window.scrollTo(0,0);
  // }, [blog])

  return <>
    <Helmet title={blog.title} />
    <section>
    <Container>
      <Row>
        <Col lg='8' md='8'>
          <div className="blog__details">
            <img src={blog.imgUrl} alt="" className='w-100 mb-4 ' />
            <h2 className="section__title">{blog.title}</h2>
            <div className="blog__publisher d-flex align-items-center gap-4 mb-4">
              <span className='blog__author'>
                <i class="ri-user-2-line"></i> {blog.author}
              </span>

              <span className="d-flex align-items-center gap-1 section__description">
                <i class="ri-calendar-event-line"></i> {blog.date}
              </span>

              <span className="d-flex align-items-center gap-1 section__description">
                <i class="ri-time-line"></i> {blog.time}
              </span>
            </div>
            <p className="section__description">
              {blog.description}
            </p>
            <h6 className="ps-5 fw-normal"><blockquote className='fs-4'>{blog.quote}</blockquote></h6>
            <p className="section__description">
              {blog.description}
            </p>
          </div>
          
            {/* =========== Comment Form ============ */}
            <div className="leave__comment-form mt-5">
              <h4>Leave a Comment</h4>
              <p className="section__description">You must sign-in to coment on a post.</p>

              <Form>
                <FormGroup>
                  <textarea rows={5} className="w-100 py-2 px-3" placeholder="Comment..."></textarea>
                </FormGroup>
                <div className="text-end">
                <button className="btn mt-3 comment__btn">Post a Comment</button>
                </div>
              </Form>
            </div>
          <div className="comment__list mt-5">
            <h4 className="mb-5">3 Comments</h4>
            <div className="single__comment d-flex gap-3">
            <img src={commentImg01} alt="" />
            <div className="comment__content">
              <h6 className='fw-bold'>David Visa</h6>
              <p className="section__descreption mb-0">14 Jan, 2022</p>
              <p className="section__description">Lorem ipsum dolor sit amet consectetur adipisicing elit. Blanditiis, totam earum suscipit ad laborum vitae ipsam, nam quas libero voluptatibus fugiat mollitia eum saepe at laboriosam non, tempora pariatur illum.</p>

              <span className="reply d-flex align-items-center gap-1">
              <i class="ri-reply-line"></i> Reply
              </span>
            </div>
            </div>
          </div>
        </Col>

        <Col lg='4' md='4'>
          <div className="recent__post mb-4">
            <h5 className='fw-bold'>Recent Posts</h5>
          </div>
          {
            blogData.map(item=>(
              <div className="recent__blog-post mb-4" key={item.id}>
                  <div className="recent__blog-item d-flex gap-3">
                    <img src={item.imgUrl} alt="" className='w-25 rounded-2' />
                    <h6><Link to={`/blog/${item.title}`}>{blog.title}</Link></h6>
                  </div>
              </div>
            ))
          }
        </Col>
      </Row>
    </Container>
    </section>
  </>
}

export default BlogDetails