import React from 'react';
import '../../styles/our-members.css';
import {Col} from 'reactstrap';
import { Link } from 'react-router-dom';
import membersData from '../../assets/data/membersData';

const OurMembers = () => {
  return <>
    {
    membersData.map((item,index)=>(
        <Col lg='3' md='3' sm='4' xs='6' key={index} className='mb-4'>
            <div className="single__member">
                <div className="single__member-img">
                    <img src={item.imgUrl} alt="" className='w-100' />

                    <div className="single__member-social">
                        <Link to={item.fbUrl}><i class="ri-facebook-line"></i></Link>
                        <Link to={item.twitUrl}><i class="ri-twitter-line"></i></Link>
                        <Link to={item.linkedinUrl}><i class="ri-linkedin-line"></i> </Link>
                        <Link to={item.instaUrl}><i class="ri-instagram-line"></i></Link>
                    </div>
                </div>

                <h6 className='text-center mb-3 mt-3'>{item.name}</h6>
                <p className="section__description text-center">{item.experience}</p>
            </div>
        </Col>
    ))
  }
  </>
}

export default OurMembers