import React from 'react';
import { Routes,Route, Navigate } from 'react-router-dom';
import Home from "../pages/Home";
import About from "../pages/About";
import CarListing from "../pages/CarListing";
import CarDetails from "../pages/CarDetails";
import Blog from "../pages/Blog";
import BlogDetails from "../pages/BlogDetails";
import NotFound from "../pages/NotFound";
import Contact from '../pages/Contact';
import Login from '../components/Auth/Login';
import Register from '../components/Auth/Register';

const Routers = () => {
  return (
    <>
        <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/home' element={<Home />} />
            <Route path='/about' element={<About />} />
            <Route path='/cars' element={<CarListing />} />
            <Route path='/cars/:slug' element={<CarDetails />} />
            <Route path='/blog' element={<Blog />} />
            <Route path='/blog/:slug' element={<BlogDetails />} />
            <Route path='/contact' element={<Contact />} />
            <Route path='/auth' element={<Navigate to='/auth/login' />} />
            <Route path='/auth/login' element={<Login />} />
            <Route path='/auth/register' element={<Register />} />
            <Route path='*' element={<NotFound />} />
        </Routes>
    </>
  )
}

export default Routers