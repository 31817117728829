const socialLinks = [
    {
        url: "#",
        icon: "ri-facebook-fill",
    },
    {
        url: "#",
        icon: "ri-instagram-fill",
    },
    {
        url: "#",
        icon: "ri-linkedin-fill",
    },
    {
        url: "#",
        icon: "ri-twitter-fill",
    },
  ];

export default socialLinks
