import React from 'react';
import {Col} from 'reactstrap';
import '../../styles/blog-item.css';
import {Link} from 'react-router-dom';
import blogData from '../../assets/data/blogData';

const BlogList = () => {
  return <>
        {
            blogData.map(item=>(
                <BlogItem item={item} key={item.id} />
            ))
        }
    </>
}

const  BlogItem = ({item}) =>{

    const {imgUrl, title, author, date, time, description} = item

    return <Col lg='4' md='6' sm='6' className='mb-5'>
        <div className="blog__item">
            <img src={imgUrl} alt="" className='w-100' />
            <div className="blog__info p-3">
                <Link to={`${title}`} className='blog__title'>{title}</Link>
                <p className="section__description mt-3">
                    {
                        description.length > 100 ? description.substr(0,100) : description
                    }
                </p>

                <Link to={`${title}`} className='read__more'>Read More</Link>

                <div className="pt-3 mt-3 blog__time d-flex align-items-center justify-content-between">
                    <span className='blog__author'>
                    <i class="ri-user-2-line"></i> {author}
                    </span>

                    <div className="d-flex align-items-cneter gap-3">
                        <span className="d-flex align-items-center gap-1 section__description">
                        <i class="ri-calendar-event-line"></i> {date}
                        </span>

                        <span className="d-flex align-items-center gap-1 section__description">
                        <i class="ri-time-line"></i> {time}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </Col>
}

export default BlogList