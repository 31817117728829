import ava01 from '../../assets/all-images/ava-1.jpg';
import ava02 from '../../assets/all-images/ava-2.jpg';
import ava03 from '../../assets/all-images/ava-3.jpg';
import ava04 from '../../assets/all-images/ava-4.jpg';

const membersData = [
    {
        name: 'Jhon Doe',
        experience: '5 years of experience',
        fbUrl: '#',
        instaUrl: '#',
        twitUrl: '#',
        linkedinUrl: '#',
        imgUrl: ava01,
    },
    {
        name: 'David Lisa',
        experience: '5 years of experience',
        fbUrl: '#',
        instaUrl: '#',
        twitUrl: '#',
        linkedinUrl: '#',
        imgUrl: ava02,
    },
    {
        name: 'Hilton King',
        experience: '5 years of experience',
        fbUrl: '#',
        instaUrl: '#',
        twitUrl: '#',
        linkedinUrl: '#',
        imgUrl: ava03,
    },
    {
        name: 'David Lisa',
        experience: '5 years of experience',
        fbUrl: '#',
        instaUrl: '#',
        twitUrl: '#',
        linkedinUrl: '#',
        imgUrl: ava04,
    },
]

export default membersData