import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, ListGroup, ListGroupItem, Row } from "reactstrap";
import "../../styles/footer.css";

const quickLinks = [
  {
    path: "/about",
    display: "About",
  },
  {
    path: "#",
    display: "Privacy Policy",
  },
  {
    path: "/cars",
    display: "Car Listing",
  },
  {
    path: "/blog",
    display: "Blog",
  },
  {
    path: "/contact",
    display: "Contact",
  },
];

const Footer = () => {
  const date = new Date();
  const year = date.getFullYear();
  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col lg="4" md="12" sm="12">
            <div className="logo footer__logo">
              <h1>
                <Link to="/home" className="d-flex align-items-center gap-3">
                  <i class="ri-roadster-fill"></i>
                  <span>
                    Rent Car <br /> Service
                  </span>
                </Link>
              </h1>
            </div>
            <p className="footer__logo-content">
              Lorem ipsum dolor sit amet consectetur, adipisicing elit.
              Laboriosam repellendus repellat, sunt nesciunt tempore
              voluptatibus enim pariatur! Iusto molestias quidem illum dolorum.
              Asperiores corrupti tempore ipsa eveniet culpa qui odio!
            </p>
          </Col>

          <Col lg="2" md="6" sm="6">
            <div className="mb-4">
              <h5 className="footer__link-title">Quick Links</h5>
              <ListGroup>
                {quickLinks.map((item, index) => (
                  <ListGroupItem key={index} className="p-0 mt-3 quick__link">
                    <Link to={item.path}>{item.display}</Link>
                  </ListGroupItem>
                ))}
              </ListGroup>
            </div>
          </Col>

          <Col lg="3" md="6" sm="6">
            <div className="mb-4">
              <h5 className="footer__link-title mb-4">Head Office</h5>
              <p className="office__info">
                Dip-1, Dubai Investment Park, Dubai, UAE
              </p>
              <p className="office__info">Phone: (+971) 52 680 4549</p>
              <p className="office__info">Email: contactDev@gmail.com</p>
              <p className="office__info">Office Time: 10apm - 7pm</p>
            </div>
          </Col>

          <Col lg="3" md="12">
            <div className="mb-4">
              <h5 className="footer__link-title mb-4">Newsletter</h5>
              <p className="section__descreption">Subscribe our newsletter</p>
              <div className="newsletter">
                <input className="w-100" type="email" placeholder="Email" />
                <span>
                  <i class="ri-send-plane-fill"></i>
                </span>
              </div>
            </div>
          </Col>

          <Col lg="12">
            <div className="footer__bottom">
              <p className="section__descreption d-flex align-items-center justify-content-center gap-1 pt-4">
                <i class="ri-copyright-line"></i>Copyright {year}, Developed by{" "}
                <strong>
                  <a
                    style={{ textDecoration: "none", color: "#ffffff99" }}
                    href="https://nepign.com"
                    target="_blank"
                  >
                    NEPIGN.
                  </a>
                </strong>{" "}
                All rights reserved.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
