
import React from 'react'
import Helmet from '../components/Helmet/Helmet'
import CommonSection from '../components/UI/CommonSection';
import AboutSection from '../components/UI/AboutSection';
import { Container, Row, Col } from 'reactstrap';
import BecomeDriver from '../components/UI/BecomeDriverSection';
import driverImg from '../assets/all-images/drive.jpg';
import OurMembers from '../components/UI/OurMembers';
import '../styles/about.css';

const About = () => {
  return (
    <>
      <Helmet title='About'></Helmet>
      <CommonSection title='About' />
      <AboutSection aboutClass='aboutPage'/>

      <section className="about__page-section">
        <Container>
          <Row>
            <Col lg='6' md='6' sm='12' className='about__page-img'>
              <img src={driverImg} alt="" className='w-100 rounded-3' />
            </Col>

            <Col lg='6' md='6' sm='12'>
              <div className="about__page-content">
                <h2 className="section__title">We Are Comitted To Provide Safe Ride Solutions</h2>

                <p className='section__description'>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur ducimus laudantium quos doloribus! Ut aliquam facere possimus officia? At veritatis iste dicta fuga, ex dolore possimus suscipit nam perferendis illum?
                </p>

                <p className='section__description'>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur ducimus laudantium quos doloribus! Ut aliquam facere possimus officia? At veritatis iste dicta fuga, ex dolore possimus suscipit nam perferendis illum?
                </p>

                <div className='d-flex align-items-center gap-3 mt-4'>
                  <span className='fs-4'><i class="ri-phone-line"></i></span>

                  <div>
                    <h6 className="section__subtitle">Need Any Help?</h6>
                    <h4>(+971) 52 680 4549</h4>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <BecomeDriver />

      <section>
        <Container>
          <Row>
            <Col lg='12' className='mb-5 text-center'>
              <h6 className="section__subtitle">Experts</h6>
              <h4 className="section__title">Our Members</h4>
            </Col>
            <OurMembers />
          </Row>
        </Container>
      </section>
    </>
    )
}

export default About