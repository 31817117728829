import React from 'react';
import Helmet from '../components/Helmet/Helmet';
import CommonSection from '../components/UI/CommonSection';
import {Container, Row, Col, Form, FormGroup, Input} from 'reactstrap';
import { Link } from 'react-router-dom';
import socialLinks from '../assets/data/socialLinks';
import '../styles/contact.css';

const Contact = () => {

  return <>
    <Helmet title='Contact Us' />
    <CommonSection title='Contact Us' />
    <section>
        <Container>
            <Row>
                <Col lg='7' md='7'>
                  <h6 className="fw-bold mb-4">Get In Touch</h6>

                  <Form>
                    <FormGroup className='d-flex align-items-center gap-3 contact__form'>
                      <Input type='text' placeholder='First Name' />
                      <Input type='text' placeholder='Last Name' />
                    </FormGroup>
                    <FormGroup className='contact__form'>
                      <Input type='email' placeholder='Email Address' />
                    </FormGroup>
                    <FormGroup className='contact__form'>
                      <textarea className='textarea' rows={5} placeholder='Your Message...'></textarea>
                    </FormGroup>
                    <button type='submit' className='contact__btn'>Send Message</button>
                  </Form>
                </Col>
                <Col lg='5' md='5'>
                  <div className="contact__info">
                    <h6 className="fw-bold">Contact Information</h6>
                    <p className="section__descreption">70 Street, Dip #1, Dubai Investment Park, Dubai, UAE</p>
                    
                    <div className="d-flex align-items-center gap-2">
                      <h6 className='mb-0 fs-6'>Phone:</h6>
                      <p className="section__descreptio mb-0">(+971) 52 680 4549</p>
                    </div>

                    <div className="d-flex align-items-center gap-2">
                      <h6 className='mb-0 fs-6'>Email:</h6>
                      <p className="section__descreptio mb-0">nobita@writeme.com</p>
                    </div>

                    <h6 className="fw-bold mt-4">Follow Us</h6>

                    <div className="d-flex align-items-center gap-4 mt-3">
                      {
                        socialLinks.map((item, index)=>(
                          <Link to={item.url} key={index} className='social__link-icon'><i class={item.icon}></i></Link>
                        ))
                      }
                    </div>
                  </div>
                </Col>
            </Row>
        </Container>
    </section>
  </>
}

export default Contact