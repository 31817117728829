import React from 'react';
import '../../styles/payment-method.css';
import masterCard from '../../assets/all-images/master-card.jpg';
import paypal from '../../assets/all-images/paypal.jpg';

const PaymentMethod = () => {
  return <>
    <div className="payment">
        <label className="d-flex align-items-center gap-2">
            <input name='payment' type="radio" /> Direct Bank Transfer
        </label>
    </div>

    <div className="payment mt-3">
        <label className="d-flex align-items-center gap-2">
            <input name='payment' type="radio" /> Cheque Payment
        </label>
    </div>
    <div className="payment mt-3 d-flex align-items-center justify-content-between">
        <label className="d-flex align-items-center gap-2">
            <input name='payment' type="radio" /> Master Card
        </label>
        <img src={masterCard} alt="" />
    </div>
    <div className="payment mt-3 d-flex align-items-center justify-content-between">
        <label className="d-flex align-items-center gap-2">
            <input name='payment' type="radio" /> Paypal
        </label>
        <img src={paypal} alt="" />
    </div>
    <div className='payment text-end mt-5'>
        <button>Reserve Now</button>
    </div>
  </>
}

export default PaymentMethod