import React from 'react';
import { Form, FormGroup, Input } from 'reactstrap';
import {Container, Row, Col} from 'reactstrap';
import '../../styles/login.css';
import {Link} from 'react-router-dom';
import socialLinks from '../../assets/data/socialLinks';
import Helmet from '../Helmet/Helmet';
import CommonSection from '../UI/CommonSection';

const Login = () => {
  return <>
    <Helmet title='Log In' />
    <CommonSection title='Log In' />
    <section className='auth__section'>
        <Container>
            <Row>
                <Col lg='12' className='login__container rounded-2 shadow'>
                    <Row>
                        <Col lg='6' className='auth__login-left d-flex align-items-center justify-content-end flex-column'>
                            <span className='social__icon-auth mb-5'>
                                {
                                    socialLinks.map((item, index)=>(
                                    <Link to={item.url} key={index} className='auth__social-icon'><i class={item.icon}></i></Link>
                                    ))
                                }
                            </span>
                            <div className='w-100 text-center auth__login-signp mb-5'>
                                <label>Don't have an account?</label><br />
                                <Link to='/auth/register'>Signup</Link>
                            </div>
                        </Col>
                        <Col lg='6' className='d-flex flex-column align-items-center justify-content-cneter p-5'>
                            <div style={{width:'80%'}} className="d-flex align-items-center justify-content-center">
                                <div className="logo">
                                    <h1>
                                        <Link to='/home' className='d-flex align-items-center gap-3'>
                                        <i class="ri-roadster-fill"></i>
                                        <span>Rent Car <br /> Service</span>
                                        </Link>
                                    </h1>
                                </div>
                            </div>
                            <Form className='form auth__form-login'>
                                <FormGroup className='auth__form-group'>
                                    <Input type='email' placeholder='Email Address' />
                                </FormGroup>
                                <FormGroup className='auth__form-group'>
                                    <Input type='password' placeholder='Password' />
                                </FormGroup>
                                <FormGroup className='auth__form-group'>
                                    <label className='d-flex align-items-center gap-1'><input type="checkbox" /> Remember Me</label>
                                </FormGroup>
                                <button className='auth__btn login__btn' disabled>LOGIN</button>
                            </Form>
                            <div className='forgot__section'>
                                <Link to='/reset-password'>Forgot Password?</Link>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    </section>
  </>
}

export default Login